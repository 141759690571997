import {Box, Grid, Typography} from '@material-ui/core'
import classNames from 'classnames'
import debounce from 'debounce'
import PropTypes from 'prop-types'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import CustomButton from '@components/CustomButton'
import CustomCheckbox from '@components/CustomCheckbox'
import CustomPlaceholder from '@components/CustomPlaceholder'
import {CustomSelectItem} from '@components/CustomSelect'
import CustomTable, {ColumnData} from '@components/CustomTable'
import CustomTextInput from '@components/CustomTextInput'
import CyclisHeader from '@components/CyclisHeader'
import {TemplateContainerComponent} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {navigate} from '@redux/slices/navigation-slice'
import {SearchIconSvg, EmptyStateIconSvg} from '@svg/index'
import {
	getBicycles,
	getBicyclesCsv,
	getBicyclesNextPage,
	resetBicyclesCsv,
} from '@templates/BicycleOverview/bicycles-slice'
import {NavigationStorageItems} from '@utils/constants/local-storage-constants'
import {LanguageContext} from '@utils/context'
import {formatIsoToPrettyDate} from '@utils/date'
import {downloadFileFromString} from '@utils/download-helper'
import {EnumDictionary} from '@utils/enum-utils'
import {useThrottledEffect} from '@utils/hooks'
import {SortDirection} from '@utils/hooks/TableDataProvider'
import {toMax15Characters, toPrettyPrice} from '@utils/string-utils'
import {Bicycle, CommonProps} from '@utils/types'
import {BicycleStatus} from '@utils/types/status'

import useStyles from './style.hook'

interface StatusMapper extends CommonProps {
	className: string
	label: string
}

interface BicycleOverviewLocation extends Location {
	state: {
		status: BicycleStatus[]
		searchTerm?: string
		sort?: {
			direction: SortDirection
			property: string
		}
	}
}

interface BicycleOverviewProps extends CommonProps {
	location: BicycleOverviewLocation
}

/**
 * BicycleOverview
 */
const BicycleOverview: React.FC<BicycleOverviewProps> = ({location}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()
	const dispatch = useDispatch()
	const {t} = useTranslation(undefined, {useSuspense: false})
	const {activeLanguage} = useContext(LanguageContext)

	const [sort, setSort] = useState(
		(location.state && location.state.sort) || {
			direction: SortDirection.DESC,
			property: 'salesOrderNumber',
		}
	)

	const [searchTerm, setSearchTerm] = useState(
		(location.state && location.state.searchTerm) || undefined
	)
	const [bicycleStatus, setBicycleStatus] = useState<string[]>(() => {
		if (location.state && location.state.status) {
			return location.state.status
		}

		return []
	})

	const {firstName, lastName} = useSelector((state: RootState) => state.auth)
	const {
		bicycles,
		success: bicyclesSuccess,
		loading: bicyclesLoading,
		paginationHasMoreData,
		paginationCursor,
		statistics,
		csvContent,
		loadingCsv,
		csvSuccess,
	} = useSelector((state: RootState) => state.bicycles)

	const rootRef = useRef<any>(null)

	const statusMapping: EnumDictionary<BicycleStatus, StatusMapper> = {
		[BicycleStatus.ALL]: {
			className: classes.greyPill,
			label: t('BicycleStatusAll'),
		},
		[BicycleStatus.CYCLIS_ORDERED]: {
			className: classes.greyPill,
			label: t('BicycleStatusCyclisOrdered'),
		},
		[BicycleStatus.DEALER_ORDERED]: {
			className: classes.yellowPill,
			label: t('BicycleStatusDealerOrdered'),
		},
		[BicycleStatus.DELIVERY_DATE_GIVEN]: {
			className: classes.redPill,
			label: t('BicycleStatusDeliveryDateGiven'),
		},
		[`${BicycleStatus.READY_FOR_PICKUP},${BicycleStatus.PICKUP_INFO_AVAILABLE}`]:
			{
				className: classes.bluePill,
				label: t('BicycleStatusReadyForPickup'),
			},
		[BicycleStatus.DELIVERY_COMPLETED]: {
			className: classes.greenPill,
			label: t('BicycleStatusDeliveryCompleted'),
		},
		[BicycleStatus.CUSTOMER_LEASE_CANCELED]: {
			className: classes.greyPill,
			label: t('BicycleStatusCanceled'),
		},
		[BicycleStatus.END_LEASE]: {
			className: classes.greyPill,
			label: t('BicycleStatusEndLease'),
		},
	}

	const fillTableSelect = (): CustomSelectItem[] => {
		const selectItems: CustomSelectItem[] = []
		for (let i = 0; i < Object.keys(statusMapping).length; i++) {
			selectItems.push({
				value: Object.keys(statusMapping)[i],
				label: (
					<Box className={classes.tableStatusDropdown}>
						<CustomCheckbox
							propsToDelegate={{
								checked:
									bicycleStatus.length === 0 &&
									Object.keys(statusMapping)[i] === BicycleStatus.ALL
										? true
										: bicycleStatus.includes(Object.keys(statusMapping)[i]),
							}}
						/>
						<Typography variant={'body1'}>
							{
								statusMapping[Object.keys(statusMapping)[i] as BicycleStatus]!
									.label
							}
						</Typography>
					</Box>
				),
			})
		}

		return selectItems
	}

	const tableCells: ColumnData[] = [
		{
			id: 0,
			label: t('BicycleOverviewTableHeaderSoNum'),
			propertyName: 'salesOrderNumber',
			noTranslate: true,
			toBodyClass: (): string =>
				classNames(classes.tableValue, classes.purpleTableValue),
			toBodyValue: (bike: Bicycle): string =>
				toMax15Characters(bike.salesOrderNumber),
			toTooltipValue: (bike: Bicycle): string => bike.salesOrderNumber,
		},
		{
			id: 1,
			label: t('BicycleOverviewTableHeaderName'),
			propertyName: 'cyclistFirstName',
			noTranslate: true,
			toBodyClass: (): string => classNames(classes.tableValue),
			toBodyValue: (bike: Bicycle): string =>
				toMax15Characters(`${bike.cyclistFirstName} ${bike.cyclistLastName}`),
			toTooltipValue: (bike: Bicycle): string =>
				`${bike.cyclistFirstName} ${bike.cyclistLastName}`,
		},
		{
			id: 2,
			label: t('BicycleOverviewTableHeaderBicycle'),
			propertyName: 'brand',
			noTranslate: true,
			toBodyClass: (): string => classNames(classes.tableValue),
			toBodyValue: (bike: Bicycle): string =>
				toMax15Characters(`${bike.brand} ${bike.model || 'N/A'}`),
			toTooltipValue: (bike: Bicycle): string =>
				`${bike.brand} ${bike.model || 'N/A'}`,
		},
		{
			id: 3,
			label: t('BicycleOverviewTableHeaderExpectedDeliveryDate'),
			propertyName: 'expectedDeliveryDate',
			toBodyClass: (): string => classNames(classes.tableValue),
			toBodyValue: (bike: Bicycle): string =>
				bike.expectedDeliveryDate
					? formatIsoToPrettyDate(`${bike.expectedDeliveryDate}`)
					: 'N/A',
			toTooltipValue: (bike: Bicycle): string =>
				bike.expectedDeliveryDate
					? formatIsoToPrettyDate(`${bike.expectedDeliveryDate}`)
					: 'N/A',
		},
		{
			id: 4,
			label: t('BicycleOverviewTableHeaderPickupDate'),
			propertyName: 'pickupDate',
			toBodyClass: (): string => classNames(classes.tableValue),
			toBodyValue: (bike: Bicycle): string =>
				bike.pickupDate ? formatIsoToPrettyDate(bike.pickupDate) : 'N/A',
			toTooltipValue: (bike: Bicycle): string =>
				bike.pickupDate ? formatIsoToPrettyDate(bike.pickupDate) : 'N/A',
		},
		{
			id: 5,
			label: t('BicycleOverviewTableHeaderStartOfContract'),
			propertyName: 'startOfContract',
			toBodyClass: (): string => classNames(classes.tableValue),
			toBodyValue: (bike: Bicycle): string =>
				bike.startOfContract
					? formatIsoToPrettyDate(bike.startOfContract)
					: 'N/A',
			toTooltipValue: (bike: Bicycle): string =>
				bike.startOfContract
					? formatIsoToPrettyDate(bike.startOfContract)
					: 'N/A',
		},
		{
			id: 6,
			label: t('BicycleOverviewTableHeaderEndOfContract'),
			propertyName: 'endOfContract',
			toBodyClass: (): string => classNames(classes.tableValue),
			toBodyValue: (bike: Bicycle): string =>
				bike.endOfContract ? formatIsoToPrettyDate(bike.endOfContract) : 'N/A',
			toTooltipValue: (bike: Bicycle): string =>
				bike.endOfContract ? formatIsoToPrettyDate(bike.endOfContract) : 'N/A',
		},
		{
			id: 9,
			label: t('BicycleOverviewTableHeaderCurrentMaintenanceBudget'),
			propertyName: 'availableMaintenanceBudget',
			toBodyClass: (): string => classNames(classes.tableValue),
			toBodyValue: (bike: Bicycle): string =>
				`${toPrettyPrice(bike.availableMaintenanceBudget) || 'N/A'}`,
			toTooltipValue: (bike: Bicycle): string =>
				`${toPrettyPrice(bike.availableMaintenanceBudget) || 'N/A'}`,
		},
		{
			id: 8,
			label: t('BicycleOverviewTableHeaderBicycleStatus'),
			propertyName: 'status',
			dropdown: {
				dropdownItems: fillTableSelect(),
				value: bicycleStatus.length > 0 ? bicycleStatus : [BicycleStatus.ALL],
				defaultValue: BicycleStatus.ALL,
				multiple: true,
				handleSelectChange: (statuses: BicycleStatus[]): void => {
					setBicycleStatus(
						statuses.indexOf(BicycleStatus.ALL) === -1 ? statuses : []
					)
				},
				handleDropdownRenderValue: ((statuses: BicycleStatus[]): string =>
					`Status (${
						statuses.indexOf(BicycleStatus.ALL) === -1
							? `${statuses.length} selected`
							: statusMapping[BicycleStatus.ALL]!.label
					})`) as any,
			},
			toBodyClass: (bike: Bicycle): string => {
				const status = Object.keys(statusMapping).find((status) =>
					status.includes(bike.bicycleStatus)
				) as BicycleStatus
				return classNames(
					classes.tableValue,
					classes.pill,
					status ? statusMapping[status]!.className : classes.greyPill
				)
			},
			toBodyValue: (bike: Bicycle): string => {
				const status = Object.keys(statusMapping).find((status) =>
					status.includes(bike.bicycleStatus)
				) as BicycleStatus
				return status ? statusMapping[status]!.label : 'N/A'
			},
			toTooltipValue: (bike: Bicycle): string => {
				const status = Object.keys(statusMapping).find((status) =>
					status.includes(bike.bicycleStatus)
				) as BicycleStatus
				return status ? statusMapping[status]!.label : 'N/A'
			},
		},
	]

	const clickBicycleRow = (bicycle: Bicycle): void => {
		localStorage.setItem(NavigationStorageItems.PREVIOUS_LOCATION, 'bicycles')

		dispatch(
			navigate(
				`/${activeLanguage}/app/bicycles/detail?id=${bicycle.bicycleId}`,
				{
					previousLocation: {language: activeLanguage},
					state: {
						sort,
						searchTerm,
						bicycleStatus,
					},
				}
			)
		)
	}

	const handleScrollPagination = (): void => {
		if (rootRef.current) {
			const scrollTopMax =
				rootRef.current.scrollHeight - rootRef.current.clientHeight
			const scrollPercent = (rootRef.current.scrollTop / scrollTopMax) * 100

			if (scrollPercent >= 30 && paginationHasMoreData && !bicyclesLoading) {
				dispatch(
					getBicyclesNextPage(
						paginationCursor,
						searchTerm,
						sort.property,
						sort.direction,
						bicycleStatus.join(',')
					)
				)
			}
		}
	}

	const changeSort = (property: string): void => {
		// Flip sorting order if same property
		if (property === sort.property) {
			setSort({
				...sort,
				direction:
					sort.direction === SortDirection.DESC
						? SortDirection.ASC
						: SortDirection.DESC,
			})
		} else {
			// Else set new property as sorting property and default descending order
			setSort({
				direction: SortDirection.DESC,
				property,
			})
		}
	}

	const onExport = (): void => {
		dispatch(
			getBicyclesCsv(
				searchTerm,
				sort.property,
				sort.direction,
				bicycleStatus.join(',')
			)
		)
	}

	/**
	 * Get status(es) from passed navigation state
	 */
	useEffect(() => {
		if (location.state && location.state.status) {
			setBicycleStatus(location.state.status)
		}
	}, [location, setBicycleStatus])

	/**
	 * Download Bicycles CSV on successful retrieval
	 */
	useEffect(() => {
		if (csvSuccess && csvContent) {
			downloadFileFromString(csvContent, 'bicycles-table.csv')
		}
	}, [csvSuccess, csvContent])

	/**
	 * Reset CSV state on page unmount
	 */
	useEffect(() => {
		return (): void => {
			dispatch(resetBicyclesCsv())
		}
	}, [])

	/**
	 * Fetch new bicycles result when sort changes
	 */
	useEffect(() => {
		const statuses =
			location.state && location.state.status
				? location.state.status.join(',')
				: bicycleStatus.join(',')
		dispatch(
			getBicycles(
				undefined,
				searchTerm,
				sort.property,
				sort.direction,
				statuses,
				!bicyclesSuccess
			)
		)
	}, [sort])

	useEffect(() => {
		dispatch(
			getBicycles(
				undefined,
				searchTerm,
				sort.property,
				sort.direction,
				bicycleStatus.join(',')
			)
		)
	}, [bicycleStatus])

	useThrottledEffect(
		() => {
			if ((searchTerm && searchTerm.length >= 3) || searchTerm === '') {
				dispatch(
					getBicycles(
						undefined,
						searchTerm,
						sort.property,
						sort.direction,
						bicycleStatus.join(',')
					)
				)
			}
		},
		1000,
		[searchTerm]
	)

	const MyBicycleStats: React.FC = () => (
		<Box id={`bicycle-stats-box`} className={classes.statsGrid}>
			<Grid id={`bicycle-stats-grid`} spacing={8} container>
				<Grid item xs={1} />
				<Grid item xs={10}>
					<Box id={`bicycle-stats-header-box`} className={classes.headerBox}>
						<Box id={`bicycle-stats-title-box`} className={classes.titleBox}>
							<Typography
								id={`bicycle-header-title`}
								className={classes.headerTitle}
								variant={'h1'}
							>
								{t('BicycleOverviewHeaderTitle')}
							</Typography>
							<Typography
								id={`bicycle-stats-header-subtitle`}
								className={classes.headerSubTitle}
								variant={'body1'}
							>
								{t('BicycleOverviewHeaderSubTitle')}
							</Typography>
						</Box>
						<CustomButton
							className={classes.exportButton}
							id={`bicycle-overview-export-button`}
							type="outlined"
							text={t('BicycleOverviewExportButton')}
							propsToDelegate={{onClick: onExport, disabled: loadingCsv}}
						/>
					</Box>
					<Box className={classes.statsCardsContainer}>
						<Box
							className={classNames(
								classes.statsCard,
								classes.cardEffect,
								classes.statsCardRightMargin
							)}
						>
							<Typography
								id={`bicycle-stats-records-title`}
								className={classes.statsTitle}
								variant={'body1'}
							>
								{t('BicycleOverviewTotalNumberOfRecords')}
							</Typography>
							<Typography
								id={`bicycle-stats-records-value`}
								className={classes.statsValue}
								variant={'body1'}
							>
								{statistics.totalBicycles}
							</Typography>
						</Box>
						<Box className={classNames(classes.statsCard, classes.cardEffect)}>
							<Typography
								id={`bicycle-stats-lease-title`}
								className={classes.statsTitle}
								variant={'body1'}
							>
								{t('BicycleOverviewTotalLeaseAmountFee')}
							</Typography>
							<Typography
								id={`bicycle-stats-lease-value`}
								className={classes.statsValue}
								variant={'body1'}
							>
								{`€${Number(statistics.totalFee).toFixed(2)}`}
							</Typography>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={1} />
			</Grid>
		</Box>
	)

	return (
		<div>
			<TemplateContainerComponent
				id={'bicycle-overview-container'}
				className={classes.root}
				propsToDelegate={{
					onScroll: debounce(handleScrollPagination, 600),
					ref: rootRef,
				}}
			>
				<CyclisHeader customerName={`${firstName} ${lastName}`} />
				<MyBicycleStats />
				<CustomTextInput
					id={`bicycle-overview-search-input`}
					className={classes.searchInput}
					iconLeft={<SearchIconSvg />}
					helperText={
						searchTerm && searchTerm.length < 3
							? t('SearchAtleast3Characters')
							: ''
					}
					helperTextId={'bicycle-overview-search-helper-text'}
					propsToDelegate={{
						placeholder: t('BicycleOverviewSearchInputPlaceholder'),
						value: searchTerm,
						onChange: (e): void => setSearchTerm(e.target.value),
					}}
				/>
				<Grid container spacing={8}>
					<Grid item xs={1} />
					<Grid item xs={10}>
						<CustomTable
							id={'bicycles'}
							columnsData={tableCells}
							tableEntries={bicycles}
							sort={sort}
							handleCellClick={clickBicycleRow}
							changeSort={changeSort}
							placeholder={
								bicyclesSuccess &&
								!bicyclesLoading &&
								bicycles.length === 0 && (
									<CustomPlaceholder
										id={'bicycle-overview'}
										icon={<EmptyStateIconSvg className={classes.icon} />}
										headerText={t('BicycleOverviewCustomPlaceholderHeader')}
										subTitle={t('BicycleOverviewCustomPlaceholderSubTitle')}
									/>
								)
							}
						/>
					</Grid>
					<Grid item xs={1} />
				</Grid>
				<Box className={classes.bottomPadding} />
			</TemplateContainerComponent>
		</div>
	)
}

BicycleOverview.propTypes = {
	location: PropTypes.any,
}

export default BicycleOverview
