import {makeStyles} from '@material-ui/core/styles'

import {LARGE_GUTTER_SIZE} from '@styles/mui-theme'

/**
 * Component-scoped styles which should be used as a hook
 * inside BicycleOverview functional component
 */

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#edf2f8',
	},
	statsGrid: {
		marginTop: theme.spacing(52 / 8),
	},
	headerTitle: {
		fontSize: 36,
		fontWeight: 'bold',
		color: '#08080d',
	},
	headerSubTitle: {
		fontSize: 16,
		fontWeight: 500,
		color: '#7e7e80',
		marginTop: theme.spacing(12 / 8),
	},
	headerBox: {
		display: 'flex',
	},
	titleBox: {
		width: '85%',
	},
	exportButton: {
		height: theme.spacing(48 / 8),
		width: '15%',
	},
	cardEffect: {
		backgroundColor: '#ffffff',
		borderRadius: theme.spacing(8 / 8),
		border: `solid 1px rgba(0, 0, 0, 0.16)`,
	},
	statsCardsContainer: {
		display: 'flex',
		marginTop: theme.spacing(16 / 8),
	},
	statsCard: {
		height: theme.spacing(89 / 8),
		width: `calc(29.5% - ${LARGE_GUTTER_SIZE}px)`,
		padding: theme.spacing(20 / 8, 16 / 8),
	},
	statsCardRightMargin: {
		marginRight: theme.spacing((LARGE_GUTTER_SIZE * 2) / 8),
	},
	statsTitle: {
		fontSize: 16,
		color: '#7e7e80',
	},
	statsValue: {
		color: '#524fa2',
		fontWeight: 'bold',
		fontSize: 20,
		fontFamily: ['Montserrat', 'sans-serif'].join(','),
	},
	cellSpacing: {
		// PaddingRight: theme.spacing((LARGE_GUTTER_SIZE * 2) / 8)
	},
	tableBodyRow: {},
	tableValue: {
		fontSize: 16,
		color: '#08080d',
	},
	pill: {
		fontWeight: 600,
		textAlign: 'center',
		borderRadius: '16px',
		width: 'fit-content',
		padding: '7px 16.5px 7px 16.5px',
	},
	greenPill: {
		color: '#56847f',
		backgroundColor: 'rgba(86, 132, 127, 0.16)',
	},
	greyPill: {
		color: '#7e7e80',
		backgroundColor: 'rgba(126, 126, 128, 0.16)',
	},
	yellowPill: {
		color: '#fcbb75',
		backgroundColor: 'rgba(252, 187, 117, 0.16)',
	},
	redPill: {
		color: '#f15a5b',
		backgroundColor: 'rgba(241, 90, 91, 0.16)',
	},
	bluePill: {
		color: '#524fa2',
		backgroundColor: 'rgba(82, 79, 162, 0.16)',
	},
	purplePill: {
		color: '#5f3264',
		backgroundColor: 'rgba(95, 50, 100, 0.16)',
	},
	purpleTableValue: {
		color: '#524fa2',
		fontWeight: 500,
	},
	searchInput: {
		marginTop: theme.spacing(16 / 8),
		marginBottom: theme.spacing(16 / 8),
		marginLeft: `calc(70% - ${LARGE_GUTTER_SIZE * 2}px)`,
		width: `calc(20.8% + ${LARGE_GUTTER_SIZE * 2}px)`,
		'& p': {
			color: '#7E7E80',
			fontWeight: 500,
			position: 'absolute',
		},
	},
	strikedText: {
		textDecoration: 'line-through',
	},
	bottomPadding: {
		height: theme.spacing(120 / 8),
	},
	icon: {
		height: theme.spacing(158 / 8),
	},
	tableStatusDropdown: {
		height: theme.spacing(4),
		display: 'flex',
		alignItems: 'center',
	},
}))

export default useStyles
